// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const FASTAPI_EXPOSED_QUPRO_BASE_URL = 'https://integration-main-fastapi-exposed-qupro-service.dev-aks.grupoarpada.com'

export const environment = {
    production: false,
    apiURL: 'https://api.qupro-odoo10.dev-aks.grupoarpada.com/',
    apiToken: '5FCB9A171352774B9155E8D84F979',
    apiMaxSessionTime: 1000 * 60 * 60 * 23, // 1 day minus 1 hour
    contactAddress: 'desarrollo@grupoarpada.com',
    apiMaxCachedTime: 1000 * 60 * 60 * 10, // 10 hours
    // apiMaxSessionTime: 1000*30, // 30 seconds only for testing proposals
    baseUrl: '/',
    version: '0.0.345', // no tocar para que funcione el autoescalado de version en CI/CD
    resetPasswordUrl: 'https://integration-main-propietario.myvivienda.dev-aks.grupoarpada.com/',
    recaptchaApiKey: '6Lfz270UAAAAABX8bc8-c2edPRsten19OWDkuBxT',
    recaptchaApiKeyAlias: 'owner',
    appKeyName: 'portal-owner',
    timeToAccumulateErrors: 500,
    fastapiExposedQuproBaseURL: FASTAPI_EXPOSED_QUPRO_BASE_URL+'',
    fastapiExposedQuproQueriesBaseURL: FASTAPI_EXPOSED_QUPRO_BASE_URL + '/queries/administrative-queries',
    fastapiExposedQuproCommandsBaseURL: FASTAPI_EXPOSED_QUPRO_BASE_URL + '/commands/administrative-queries'
  };
  
  /*
   * For easier debugging in development mode, you can import the following file
   * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
   *
   * This import should be commented out in production mode because it will have a negative impact
   * on performance if an error is thrown.
   */
  import 'zone.js/dist/zone-error';  // Included with Angular CLI.
  